import "../App.scss";
import { NavLink } from "react-router-dom";
import BlueSky from "../Icons/Bluesky_Logo.png";
const TammyConfused = require("../assets/profilepics/tammy.png");

function NavBar() {
  // <CarcasoPortfolioBox imageName="test.jpg"></CarcasoPortfolioBox>
  // <CarcasoPortfolioBox imageName = {"./assets/tammy.png"} />
  return (
    <div className="NavBar">
      <NavLink to="/">
        <img
          src={TammyConfused}
          alt="HomeNavButtonImage"
          className="HomeButtonImage"
        />
      </NavLink>
      <NavLink
        to="/profilepics"
        className={({ isActive, isPending }) =>
          `homeNav ${isPending ? "pending" : isActive ? "active" : ""}`
        }
      >
        <p>Profile Pictures</p>
      </NavLink>
      <NavLink
        to="/gallery"
        className={({ isActive, isPending }) =>
          isPending ? "pending" : isActive ? "active" : ""
        }
      >
        <p>Gallery</p>
      </NavLink>
      <NavLink
        to="/doodles"
        className={({ isActive, isPending }) =>
          isPending ? "pending" : isActive ? "active" : ""
        }
      >
        <p>Doodles & Daydreams</p>
      </NavLink>
      <img
        src={BlueSky}
        alt="BlueSkyLogo"
        className="NavTwitterSocials"
        style={{ width: "40px", height: "90px", cursor: "pointer" }}
        onClick={() =>
          window.open(
        "https://bsky.app/profile/tamingthecarcasoan.bsky.social",
        "_blank"
          )
        }
      />
    </div>
  );
}

export default NavBar;
/*
     <BlueSky
        onClick={() =>
          window.open(
            "https://bsky.app/profile/tamingthecarcasoan.bsky.social",
            "_blank"
          )
        }
        className="NavTwitterSocials"
        style={{ width: "40px", height: "90px" }}
        viewBox="0 0 610 1340"
        preserveAspectRatio="none"
      />
      */