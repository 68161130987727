import "../App.scss";
import { useNavigate } from "react-router-dom";
import TammyBanner from "../assets/compressed/webp/gallerybanner.webp";
import Zoom from "react-medium-image-zoom";

import WaveMoth from "../assets/temp/tami show right.webp";
import FlowerClip from "../assets/temp/sunflower-wedding-inwitation-clipart-watercolor-bouquet-isolated-white-hand-drawn-stock-illustration-188019196.webp";
import blinky from "../assets/temp/htmlblinkie.gif";
import tempProfileBanner from "../assets/temp/tami_banner_profilepictures_no_shadow.webp";

import Balatro from "../assets/temp/brandbird1.gif";
import WhiteMage from "../assets/temp/white_mage_alt_bg.gif";
import Shen from "../assets/temp/Shen.gif";

import Twitch from "../assets/Twitch.svg";
import Bluesky from "../Icons/Bluesky_Logo.png";
import YouTube from "../assets/temp/YouTube_play_button_square_(2013-2017).svg.png";
import dice from "../assets/temp/dice.jpg";

import GalleryItems from "../lists/GalleryList";
import ProfilePicItems from "../lists/profilePicturesList";
import { useEffect, useState } from "react";

import mobyDickChapterOne from "../lists/MobyDick";

//const GalleryIsNewest = true;
const CombinedItems = [...GalleryItems, ...ProfilePicItems].filter(
  (item) => item.imageSrc !== undefined && item.imageSrc !== null
);

function HomePage() {
  const navigate = useNavigate();

  const [statusUpdates, setStatusUpdates] = useState<string[]>([]);
  const [activeBlinky, setActiveBlinky] = useState<string | null>(null);

  const [artIndex, setArtIndex] = useState<number>(0);
  const [updateIndex, setUpdateIndex] = useState<number>(0);
  const [artCounter, setArtCounter] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://umg1f8r329.execute-api.us-east-1.amazonaws.com",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            mode: "cors",
          }
        );
        const responseJson = await response.json();

        setStatusUpdates(
          responseJson.map((item: { daily: string }) => item.daily)
        );
        setUpdateIndex(Math.floor(Math.random() * responseJson.length));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  /* 
    src={
                GalleryIsNewest
                  ? GalleryItems[newArt].imageSrc
                  : ProfilePicItems[newArt].imageSrc
              }
                  */

  return (
    <div className="HomePage">
      <div className="HomePageRecentAndFunContainer">
        <div className="HomePageNewArtContainer">
          <div className="HomePageNewArtTitle">
            {artCounter >= 128
              ? "You win. I'm not putting the rest of Moby Dick in here. There's no more easter eggs. Please, just enjoy my website"
              : artCounter > 25
              ? mobyDickChapterOne[artCounter - 25]
              : artCounter > 15
              ? "Please I beg you"
              : artCounter > 5
              ? "You know I have a Gallery right?"
              : artIndex === 0
              ? "New Art!"
              : "Old Art!"}
          </div>
          <div className="HomePageNewArtImageContainer">
            <div
              className="HomePageNewArtHiddenLamp"
              onClick={() => {
                setArtIndex(() =>
                  Math.floor(Math.random() * CombinedItems.length)
                );
                setArtCounter((prevCounter) => prevCounter + 1);
              }}
            ></div>
            <Zoom>
              <img
                alt="Latest Art from my Gallery, click to learn more :3"
                src={CombinedItems[artIndex].imageSrc}
                className="HomePageNewArtImage"
              />
            </Zoom>
          </div>
        </div>
      </div>
      <div className="HomePageGalleryProfilePicContainer">
        <div className="SunflowerFact">
          {statusUpdates.length > 0 ? (
            <p>{statusUpdates.length > 0 && statusUpdates[updateIndex]}</p>
          ) : (
            <p>[EITHER IT'S LOADING OR I CAN'T CODE]</p>
          )}
        </div>
        <div className="MothWaveContainer">
          <div
            onClick={() => {
              setUpdateIndex(Math.floor(Math.random() * statusUpdates.length));
            }}
            className="MothWaveClickable"
          ></div>
          <div className="MothWaveBackupImage"></div>
        </div>
        <div className="BannerContainer">
          <img
            src={tempProfileBanner}
            alt="Tammy Banner"
            className="HomePageBanner"
            onClick={() => navigate("/profilepics")}
          />
        </div>
        <div className="BannerContainer">
          <img
            src={TammyBanner}
            alt="Tammy Banner"
            className="HomePageBanner"
            onClick={() => navigate("/gallery")}
          />
        </div>
      </div>
      <div className="SunflowerFactBottom">
        {statusUpdates.length > 0 ? (
          <p>{statusUpdates.length > 0 && statusUpdates[updateIndex]}</p>
        ) : (
          <p>...</p>
        )}
      </div>
      <div className="HomePageAboutMeContainer">
        <div className="HomePageAboutMeTitle">About Me</div>
        <div className="HomePageAboutMeTextContainer">
          <div className="HomePageAboutMeBody">
            <ul>
              <li>the second Snailman</li>
              <li>alleged pyromaniac and implied protagonist</li>
              <li>
                used to play Time Wizard on the school yard; now all I see is
                Dark Magician Girl
              </li>
              <li>
                <p className="OngoingTick">764</p> Gold comes from a former
                person
              </li>
              <li>
                left the past on my character sheet
              </li>
              <li>Hyper Light Drifter is best seen through a kaledioscope</li>
              <li>the best claim to fame is mining space rocks</li>
            </ul>
          </div>
          <div className="BlinkiesContainer">
            <p className="BlinkesContainerTitle"></p>
            <div className="BlinkyContainer">
              <img
                src={WhiteMage}
                alt={"Blinky"}
                className="Blinky"
                onClick={() => {
                  setActiveBlinky((prev) =>
                    prev === "WHITE_MAGE" ? null : "WHITE_MAGE"
                  );
                }}
              />
              {activeBlinky === "WHITE_MAGE" && (
                <p className="BlinkyText" id="blinkyWhiteMage">
                  I AM THE PRETZEL WHITE MAGE!!
                </p>
              )}
            </div>
            <div className="BlinkyContainer">
              <img
                src={Shen}
                alt={"Blinky"}
                className="Blinky"
                onClick={() => {
                  setActiveBlinky((prev) => (prev === "SHEN" ? null : "SHEN"));
                }}
              />
              {activeBlinky === "SHEN" && (
                <p className="BlinkyText" id="blinkyShen">
                  If there is one Shen main left on Earth, I am that Shen main.
                  If there are two Shen mains, I'M STILL NUMBER ONE FUCK YOU PAUL. 
                </p>
              )}
            </div>
            <div className="BlinkyContainer">
              <img
                src={Balatro}
                alt={"Blinky"}
                className="Blinky"
                onClick={() => {
                  setActiveBlinky((prev) =>
                    prev === "BALATRO" ? null : "BALATRO"
                  );
                }}
              />
              {activeBlinky === "BALATRO" && (
                <p className="BlinkyText" id="blinkyBalatro">
                  (Localthunk's GIF) I am glutton for losing Triboulet Hanging
                  Chad Photograph Blueprint runs to The Plant. I gamba'd 180
                  hours to Gold Stake all 15 decks (shout out to Painted my
                  saviour) with EVERY JOKER. All 150 Gold Stickers. It took like
                  9 runs to find the last one, Showman.
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="HomePageAboutMeFooter">
          <img
            alt={"Bluesky link"}
            src={Bluesky}
            className="SocialMediaLink"
            onClick={() =>
              window.open(
                "https://bsky.app/profile/tamingthecarcasoan.bsky.social",
                "_blank"
              )
            }
          />
          <img
            alt={"Twitch link"}
            src={Twitch}
            className="SocialMediaLink"
            onClick={() =>
              window.open("https://www.twitch.tv/tamingofspyro", "_blank")
            }
          />
          <img
            alt={"Youtube link"}
            src={YouTube}
            className="SocialMediaLink"
            style={{ borderRadius: "12px" }}
            onClick={() =>
              window.open("https://www.youtube.com/@TamingofSpyro", "_blank")
            }
          />
          <div
            className="CallToActionButton"
            onClick={() => navigate("/doodles")}
          >
            Doodles & Daydreams
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
/*
   <div className="SunflowerFactContainer">
          <p className="SunflowerFactTitle">Fun Flower Fact!</p>
          <img
            className="SunflowerFactImage"
            src={FlowerClip}
            alt="Flowers"
          ></img>
        </div>
 <div className="SunflowerFact">
            {statusUpdates.length > 0 ? (
              <p>{statusUpdates.length > 0 && statusUpdates[updateIndex]}</p>
            ) : (
              <p>...</p>
            )}
          </div>
          <button
            className="SunflowerButton"
            onClick={() => {
              setUpdateIndex(Math.floor(Math.random() * statusUpdates.length));
            }}
          >
            <span className="text">Tell Me Lies</span>
            <span aria-hidden="true" className="marquee">
              Tell Me Lies
            </span>
          </button>
          */

//  <img className="MothWaveImage" src={WaveMoth} alt="Tami waving"></img>
