import "../App.scss";
import ArtBox from "../components/artBox";
import profilePicsMasterData from "../lists/profilePicturesList";
//import { useNavigate } from "react-router-dom";

import { Suspense, useState } from "react";
import { lazy } from "react";

const ProfilePicSelector = lazy(
  () => import("../components/profilepicselector")
);

function ProfilePictures() {
  // <CarcasoPortfolioBox imageName="test.jpg"></CarcasoPortfolioBox>
  // <CarcasoPortfolioBox imageName = {"./assets/tammy.png"} />
  const [currentImage, setCurrentImage] = useState(
    profilePicsMasterData[0].imageSrc
  );

  const [index, setIndex] = useState(0);

  const imageList = profilePicsMasterData.map((items, i) => (
    <ProfilePicSelector
      imageName={items.imageSrc}
      changeImage={setCurrentImage}
      currentImage={currentImage}
      index={i}
      setIndex={setIndex}
    ></ProfilePicSelector>
  ));

 // const navigate = useNavigate();
  /*
  const navigateCommissions = () => {
    navigate("/commissions");
  };
  */
  /*
  <div className="ProfilePicturesInfoFooter">
                <Twitter
                  onClick={() =>
                    window.open("https://twitter.com/TamingOfCarcaso", "_blank")
                  }
                  className="ProfilePicturesLeftSideFooterTwitter"
                />
                <Gmail
                  onClick={() =>
                    window.open("mailto:tamingthecarcasoan@gmail.com", "_blank")
                  }
                  className="ProfilePicturesLeftSideFooterGmail"
                />
              </div>
              */
  return (
    <div className="ProfilePictures">
      <div className="ProfilePicturesBottomContainer">
        <div className="ProfilePicturesExplanationContainer">
          <div className="ProfilePicturesInnerContainer">
            <ArtBox
              imageName={currentImage}
              artTitle={profilePicsMasterData[index].artTitle}
              artDescription={profilePicsMasterData[index].artDescription}
              style={profilePicsMasterData[index].style}
              date={profilePicsMasterData[index].date}
            ></ArtBox>
            <p className="ProfilePicturesInnerContainerText">
              Click on the Image Above to View Full Screen!
            </p>
          </div>
        </div>
        <Suspense fallback={<div>Loading!</div>}>
          <div
            className="ProfilePicSelectors"
            onClick={() => {
              const element = document.querySelector(
                ".ProfilePicturesInnerContainer"
              );
              const offset = 100;
              const bodyRect = document.body.getBoundingClientRect().top;
              const elementRect = element?.getBoundingClientRect().top || 0;
              const elementPosition = elementRect - bodyRect;
              const offsetPosition = elementPosition - offset;

              window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
              });
            }}
          >
            {imageList}
          </div>
        </Suspense>
      </div>
    </div>
  );
}

export default ProfilePictures;
